import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import {
  Fonts,
  StudyRegister,
  Container,
  HealthcarePartners,
  LaypersonStudyResult,
} from '../global.css';
import FindASTudy from '../components/find-a-study/find-a-study';

const Index = ({ data, pageContext }) => {
  return (
    <Layout>
      <Container>
        <Fonts>
          <StudyRegister>
            <p className="img-c">
              <img
                src={data.studyRegisterJson.img}
                alt={data.studyRegisterJson.imgAlt}
              />
            </p>

            <h2>{data.studyRegisterJson.title}</h2>
            <p>
              {data.studyRegisterJson.description}
              <a
                href={data.studyRegisterJson.learnMoreLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                {data.studyRegisterJson.learnMore}
              </a>
            </p>
          </StudyRegister>
          <LaypersonStudyResult>
            <h2>{data.laypersonSummaryResultsJson.title}</h2>
            <p>
              {data.laypersonSummaryResultsJson.description}
              <a
                href={data.laypersonSummaryResultsJson.learnMoreLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                {data.laypersonSummaryResultsJson.learnMore}
              </a>
            </p>
          </LaypersonStudyResult>
          <HealthcarePartners
            dangerouslySetInnerHTML={{
              __html:
                data.healthcarePartnersJson.content.childMarkdownRemark.html,
            }}
          />
          <FindASTudy pageContext={pageContext} />
        </Fonts>
      </Container>
    </Layout>
  );
};
const IndexQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        studyRegisterJson {
          img
          imgAlt
          title
          description
          boldDescription
          learnMore
          learnMoreLink
        }
        healthcarePartnersJson {
          content {
            childMarkdownRemark {
              html
            }
          }
        }
        laypersonSummaryResultsJson(id: {}) {
          title
          description
          learnMore
          learnMoreLink
        }
      }
    `}
    render={data => <Index data={data} {...props} />}
  />
);

export default IndexQuery;

Index.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};
