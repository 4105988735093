import { Field, Formik } from 'formik';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledCollapse,
} from 'reactstrap';
import { getGeographicalCoordinates } from '../../functions/googleMap';
import { StyledTooltip } from '../../global.css';
import { CheckboxSet } from '../../helpers/checkbox-set';
import { useNavigation } from '../../helpers/navigate';
import Autocomplete from '../autocomplete/autocomplete';
import DisplayModalConditions from '../modal/display-conditions';
import Modal from '../modal/modal';
import { getSelectedConditions } from './conditions-filtering';
import { formInitialValues } from './constants';
import { Search } from './find-a-study.css';
import { masterDataContext } from '../../store/masterDataContext';

const HomeSearch = ({ data, pageContext }) => {
  const [isWeCanHelpOpen, setIsWeCanHelpOpen] = useState(false);
  const [isStudyTypeDropdownOpen, setIsStudyTypeDropdownOpen] = useState(false);
  const [isCollaboratorDropdownOpen, setIsCollaboratorDropdownOpen] = useState(
    false
  );
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isCountryDropdownOpem, setIsCountryDropdownOpem] = useState(false);
  const countryRef = useRef('');
  const studyTypeRef = useRef('');
  const collaboratorRef = useRef('');
  const keySuggestionRef = useRef([]);
  const textInputRef = useRef(null);
  const findAStudy = data.findAStudyJson;
  const additionalFilters = data.additionalFiltersJson;
  const { masterData } = useContext(masterDataContext);
  const navigate = useNavigation(`/${pageContext.langKey}/studies/`);

  useEffect(() => {
    let keySuggestion = [];
    if (typeof window !== 'undefined') {
      typeof masterData?.CONDITION !== 'undefined' &&
        masterData?.CONDITION.length > 0 &&
        masterData?.CONDITION?.forEach(element => {
          keySuggestion.push(element.DisplayValue);
          if (element.Children) {
            element.Children.forEach(subElement =>
              keySuggestion.push(subElement.DisplayValue)
            );
          }
        });

      typeof masterData?.COUNTRY !== 'undefined' &&
        masterData?.COUNTRY?.length > 0 &&
        masterData?.COUNTRY?.forEach(element => {
          keySuggestion.push(element.DisplayValue);
          if (element.Children) {
            element.Children.forEach(subElement =>
              keySuggestion.push(subElement.DisplayValue)
            );
          }
        });

      const uniqueSuggestions = keySuggestion.filter(
        (suggestion, index, array) => array.indexOf(suggestion) === index
      );
      keySuggestionRef.current = uniqueSuggestions;
    }
  }, [textInputRef]);

  return (
    <Search>
      <Formik
        enableReinitialize={false}
        initialValues={formInitialValues}
        onSubmit={(values, actions) => {
          if (
            values.country &&
            values.country.length &&
            values.zip &&
            values.zip.length
          ) {
            const countryName = values.country.replace('COUNTRY_', '');
            getGeographicalCoordinates(
              countryName,
              values.zip && values.zip.length ? values.zip : ''
            )
              .then(response => {
                switch (response.tag) {
                  case 'Success': {
                    const { Longitude, Latitude } = response.value;
                    values.Latitude = Latitude;
                    values.Longitude = Longitude;
                    return true;
                  }
                  case 'Error': {
                    /*eslint quotes: [2, "single", "avoid-escape"]*/
                    alert(
                      typeof response.value === 'string' &&
                        response.value === 'Not found'
                        ? 'Invalid zip code and country combination'
                        : "We can't find specified address"
                    );
                    return false;
                  }
                }
              })
              .then(success => {
                actions.setSubmitting(false);
                if (!success) {
                  return false;
                }
                navigate.withParams(values);
              });
          } else {
            actions.setSubmitting(false);
            navigate.withParams(values);
          }
        }}
        render={({ values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className={'containerS'}>
              <Col className={'title-section'} xs={12}>
                <h1>{findAStudy.title}</h1>
                <p>{findAStudy.subTitle}</p>
              </Col>
              <Col xs={12}>
                <p className={'keyword-search'}>
                  <strong>{findAStudy.searchTitle}</strong>
                  <FaInfoCircle id="keyword_search" />
                  <StyledTooltip
                    trigger="hover"
                    placement="top"
                    target="keyword_search"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          findAStudy.searchTooltip.childMarkdownRemark.html,
                      }}
                    />
                  </StyledTooltip>
                </p>
                <div className={'searchBar'}>
                  <Autocomplete
                    refs={textInputRef}
                    className={'keySearch'}
                    placeholder={findAStudy.placeholder}
                    suggestions={keySuggestionRef.current}
                    name="SearchTerm"
                    changeValue={value => {
                      setFieldValue('SearchTerm', value);
                    }}
                    userInput={values.SearchTerm}
                  />
                  <button type={'submit'} className={'search-btn redBtn'}>
                    {findAStudy.searchBtn}
                  </button>
                </div>
              </Col>
              <Col xs={12}>
                <Row className={'bgGrey search-info'}>
                  <Col lg={6} xs={12} className={'noPad left-search'}>
                    <p>
                      {findAStudy.collapseQuestion}
                      <button
                        id="togglerMore"
                        className={'toggleBtn'}
                        onTouchStart={() => {
                          setIsWeCanHelpOpen(!isWeCanHelpOpen);
                        }}
                        onClick={() => {
                          setIsWeCanHelpOpen(!isWeCanHelpOpen);
                        }}
                      >
                        {findAStudy.collapseButton}{' '}
                        {isWeCanHelpOpen ? (
                          <span className={'icon-minus'} />
                        ) : (
                          <span className={'icon-plus'} />
                        )}
                      </button>
                    </p>
                  </Col>
                  <Col lg={6} xs={12} className={'rightSearch noPad'}>
                    <p>{findAStudy.choseMore}</p>
                  </Col>
                  <UncontrolledCollapse
                    className={'help-section '}
                    toggler="#togglerMore"
                  >
                    <p className={'first'}>{findAStudy.collapseP1}</p>
                    <p>{findAStudy.collapseP2}</p>
                  </UncontrolledCollapse>
                </Row>
              </Col>
              {typeof masterData?.RECSTATUS !== 'undefined' &&
                masterData?.RECSTATUS?.length > 0 && (
                  <Col className={'trial-status '} xs={12}>
                    <Row className={'bgGrey'}>
                      <strong className={'trial-name'}>
                        {findAStudy.studyStatus}
                      </strong>
                      <div className={'status-boxes'}>
                        <CheckboxSet
                          list={masterData?.RECSTATUS}
                          name="Status"
                        />
                      </div>
                    </Row>
                  </Col>
                )}
              <Col xs={12}>
                <h2 className={'additional-title'}>
                  {additionalFilters.title}
                </h2>
              </Col>
              <div
                className={
                  'separator firstRow ' +
                  (typeof masterData?.STUDY_TYPE === 'undefined' &&
                  typeof masterData?.PATIENT_LEVEL_SHARING === 'undefined'
                    ? ' bottomLine'
                    : '')
                }
              >
                <Row>
                  {typeof masterData?.CONDITION !== 'undefined' &&
                    masterData?.CONDITION?.length > 0 && (
                      <Col lg={4} xs={12}>
                        <p className={'title'}>{additionalFilters.condition}</p>
                        <div className={'dropdown'}>
                          <Modal
                            openedModal={isModalOpened}
                            conditionVal={getSelectedConditions(
                              masterData?.CONDITION,
                              values.Conditions
                            )}
                            showModal={() => setIsModalOpened(true)}
                            hideModal={() => setIsModalOpened(false)}
                            conditionsList={values.Conditions}
                            conditions={masterData?.CONDITION}
                          >
                            <Row>
                              <div className={'conditionTitle'}>
                                <h5>
                                  {additionalFilters.modalCondition.title}
                                </h5>
                              </div>
                              <DisplayModalConditions
                                conditions={masterData?.CONDITION}
                              ></DisplayModalConditions>
                              <div className={'modalFooter'}>
                                <div className={'healthy-boxes'}>
                                  {typeof masterData?.HEALTHY_VOL !==
                                    'undefined' &&
                                    masterData?.HEALTHY_VOL?.length > 0 && (
                                      <>
                                        <p>
                                          {
                                            additionalFilters.modalCondition
                                              .healthyVolunteers
                                          }
                                        </p>
                                        <div className={'healthy-options'}>
                                          <CheckboxSet
                                            list={masterData?.HEALTHY_VOL}
                                            name="HealthyVolunteer"
                                          />
                                        </div>
                                      </>
                                    )}
                                </div>
                                <div className={'right'}>
                                  {values.Conditions.length > 0 ? (
                                    <span>
                                      {values.Conditions.length +
                                        additionalFilters.modalCondition
                                          .selectedCondition}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                  <button
                                    type="button"
                                    className={'clearBtn'}
                                    onClick={() => {
                                      setFieldValue('Conditions', []);
                                      setFieldValue('HealthyVolunteer', []);
                                    }}
                                  >
                                    <p>
                                      {
                                        additionalFilters.modalCondition
                                          .clearBtn
                                      }
                                    </p>
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setIsModalOpened(false);
                                    }}
                                    className={'buttonOk'}
                                  >
                                    {additionalFilters.modalCondition.okBtn}
                                  </button>
                                </div>
                              </div>
                            </Row>
                          </Modal>
                        </div>
                      </Col>
                    )}
                  {typeof masterData?.COUNTRY !== 'undefined' &&
                    masterData?.COUNTRY?.length > 0 && (
                      <Col lg={4} xs={12}>
                        <p className={'title country'}>
                          {additionalFilters.country}
                        </p>
                        <Dropdown
                          isOpen={isCountryDropdownOpem}
                          toggle={() => {
                            setIsCountryDropdownOpem(!isCountryDropdownOpem);
                          }}
                        >
                          <DropdownToggle
                            tag="button"
                            type="button"
                            name="StudyTypes"
                            className={'select-dropdown'}
                          >
                            <p>{countryRef.current || 'Select Country'}</p>
                            <span className={'arrowRight'}></span>
                          </DropdownToggle>
                          <DropdownMenu
                            flip={false}
                            direction="down"
                            style={{
                              maxHeight: '300px',
                              overflow: 'auto',
                            }}
                          >
                            {[
                              {
                                InternalValue: '',
                                DisplayValue: 'Select Country',
                              },
                            ]
                              .concat(masterData?.COUNTRY)
                              .map((country, index) => (
                                <DropdownItem
                                  className={`${
                                    countryRef.current ===
                                      country.DisplayValue ||
                                    (countryRef.current === '' && index === 0)
                                      ? 'selected'
                                      : ''
                                  }`}
                                  onClick={() => {
                                    setFieldValue(
                                      'country',
                                      country.InternalValue
                                    );
                                    countryRef.current = country.DisplayValue;
                                  }}
                                  key={country.InternalValue}
                                  value={country.InternalValue}
                                  id={country.InternalValue}
                                >
                                  {country.DisplayValue}
                                </DropdownItem>
                              ))}
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    )}
                  <Col lg={4} xs={12}>
                    <p className={'title'}>{additionalFilters.postalCode}</p>
                    <Field
                      type="text"
                      maxLength="10"
                      name="zip"
                      autoComplete="off"
                      className={'zip'}
                      aria-label={'zip label'}
                    />
                  </Col>
                </Row>
              </div>
              <div
                className={
                  typeof masterData?.STUDY_TYPE === 'undefined' &&
                  typeof masterData?.PATIENT_LEVEL_SHARING === 'undefined'
                    ? ''
                    : 'separator bottomLine sectionTwo '
                }
              >
                <Row>
                  {typeof masterData?.STUDY_TYPE !== 'undefined' &&
                    masterData?.STUDY_TYPE?.length > 0 && (
                      <Col lg={4} xs={12}>
                        <p className={'title study'}>
                          {additionalFilters.studyType}
                        </p>
                        <Dropdown
                          isOpen={isStudyTypeDropdownOpen}
                          toggle={() =>
                            setIsStudyTypeDropdownOpen(!isStudyTypeDropdownOpen)
                          }
                        >
                          <DropdownToggle
                            tag="button"
                            type="button"
                            name="StudyTypes"
                            className={'select-dropdown'}
                          >
                            <p>{studyTypeRef.current || 'Select Type'}</p>
                            <span className={'arrowRight'}></span>
                          </DropdownToggle>
                          <DropdownMenu
                            flip={false}
                            direction="down"
                            style={{
                              maxHeight: '300px',
                              overflow: 'auto',
                            }}
                          >
                            {[
                              {
                                InternalValue: '',
                                DisplayValue: 'Select Type',
                              },
                            ]
                              .concat(masterData?.STUDY_TYPE)
                              .map((studyType, index) => (
                                <DropdownItem
                                  className={
                                    studyTypeRef.current ===
                                      studyType.DisplayValue ||
                                    (studyTypeRef.current === '' && index === 0)
                                      ? 'selected'
                                      : ''
                                  }
                                  onClick={() => {
                                    setFieldValue(
                                      'StudyTypes',
                                      studyType.InternalValue
                                    );
                                    studyTypeRef.current =
                                      studyType.DisplayValue;
                                  }}
                                  key={studyType.InternalValue}
                                  value={studyType.InternalValue}
                                  id={studyType.InternalValue}
                                >
                                  {studyType.DisplayValue}
                                </DropdownItem>
                              ))}
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    )}
                  {typeof masterData?.PATIENT_LEVEL_SHARING !== 'undefined' &&
                    masterData?.PATIENT_LEVEL_SHARING?.length > 0 && (
                      <Col lg={4} xs={12}>
                        <p className={'title'}>
                          {additionalFilters.patientLevelData}
                        </p>
                        <div className={'patient-boxes'}>
                          <CheckboxSet
                            list={masterData?.PATIENT_LEVEL_SHARING}
                            name="PatientLevelSharing"
                          />
                        </div>
                      </Col>
                    )}
                </Row>
              </div>
              <div
                className={
                  typeof masterData?.AGERANGE === 'undefined' &&
                  typeof masterData?.GENDER === 'undefined'
                    ? ''
                    : 'separator bottomLine'
                }
              >
                <Row>
                  {typeof masterData?.AGERANGE !== 'undefined' &&
                    masterData?.AGERANGE?.length > 0 && (
                      <Col lg={4} xs={12}>
                        <p className={'title'}>{additionalFilters.ageRange}</p>
                        <div className={'age-boxes'}>
                          <CheckboxSet
                            list={masterData?.AGERANGE}
                            name="AgeRanges"
                          />
                        </div>
                      </Col>
                    )}
                  {typeof masterData?.GENDER !== 'undefined' &&
                    masterData?.GENDER?.length > 0 && (
                      <Col lg={4} xs={12}>
                        <p className={'title'}>{additionalFilters.gender}</p>
                        <div className={'gender-boxes'}>
                          <CheckboxSet
                            list={masterData?.GENDER}
                            name="Gender"
                          />
                        </div>
                      </Col>
                    )}
                </Row>
              </div>
              {typeof masterData?.PHASE !== 'undefined' &&
                masterData?.PHASE?.length > 0 && (
                  <div className={'separator bottomLine '}>
                    <Row>
                      <Col xs={12} className={additionalFilters.phases}>
                        <p className={'title'}>{'Phases'}</p>
                        <div className={'phases-boxes2'}>
                          <CheckboxSet list={masterData?.PHASE} name="Phases" />
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              {typeof masterData?.ATTACHTYPE !== 'undefined' &&
                masterData?.ATTACHTYPE?.length > 0 && (
                  <div className={'separator bottomLine '}>
                    <Row>
                      <Col xs={12}>
                        <p className={'title'}>
                          {additionalFilters.disclousureType}
                        </p>
                        <Row>
                          <Col lg={4} xs={12}>
                            <CheckboxSet
                              list={masterData?.ATTACHTYPE?.filter(
                                (_, index) => index < 5
                              )}
                              name="AttachmentTypes"
                            />
                          </Col>
                          <Col lg={4} xs={12}>
                            <CheckboxSet
                              list={masterData?.ATTACHTYPE?.filter(
                                (_, index) => index >= 5
                              )}
                              name="AttachmentTypes"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                )}
              {typeof masterData?.COLLABORATOR !== 'undefined' &&
                masterData?.COLLABORATOR?.length > 0 && (
                  <div className={'separator bottomLine collaborator'}>
                    <Row>
                      <Col lg={4} xs={12}>
                        <p className={'title'}>
                          {additionalFilters.collaborators}
                        </p>
                        <Dropdown
                          direction="up"
                          isOpen={isCollaboratorDropdownOpen}
                          toggle={() =>
                            setIsCollaboratorDropdownOpen(
                              !isCollaboratorDropdownOpen
                            )
                          }
                        >
                          <DropdownToggle
                            tag="button"
                            type="button"
                            name="Collaborators"
                            className={'select-dropdown'}
                          >
                            <p>
                              {collaboratorRef.current || 'Select Collaborator'}
                            </p>
                            <span className={'arrowRight'}></span>
                          </DropdownToggle>
                          <DropdownMenu
                            flip={false}
                            style={{
                              maxHeight: '300px',
                              height: '300px',
                              overflow: 'auto',
                              top: '-300px',
                            }}
                          >
                            {[
                              {
                                InternalValue: '',
                                DisplayValue: 'Select Collaborator',
                              },
                            ]
                              .concat(masterData?.COLLABORATOR)
                              .map((collaborator, index) => (
                                <DropdownItem
                                  className={
                                    collaboratorRef.current ===
                                      collaborator.DisplayValue ||
                                    (collaboratorRef.current === '' &&
                                      index === 0)
                                      ? 'selected'
                                      : ''
                                  }
                                  onClick={() => {
                                    setFieldValue(
                                      'Collaborators',
                                      collaborator.InternalValue
                                    );
                                    collaboratorRef.current =
                                      collaborator.DisplayValue;
                                  }}
                                  key={collaborator.InternalValue}
                                  value={collaborator.InternalValue}
                                  id={collaborator.InternalValue}
                                >
                                  {collaborator.DisplayValue}
                                </DropdownItem>
                              ))}
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </div>
                )}
              <Col xs={12} className={'bottom-search'}>
                <button
                  type="button"
                  className={'resetBtn redBtn'}
                  onClick={() => {
                    Object.keys(formInitialValues).forEach(key => {
                      setFieldValue(key, formInitialValues[key]);
                    });
                    studyTypeRef.current = '';
                    collaboratorRef.current = '';
                    countryRef.current = '';
                  }}
                >
                  {additionalFilters.resetBtn}
                </button>
                <button type={'submit'} className={'searchBtn redBtn'}>
                  {additionalFilters.searchBtn}
                </button>
              </Col>
            </div>
          </form>
        )}
      />
    </Search>
  );
};
const HomeSearchQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        studyRegisterJson {
          img
          imgAlt
          title
          description
          boldDescription
        }
        healthcarePartnersJson {
          content {
            childMarkdownRemark {
              html
            }
          }
        }
        findAStudyJson {
          title
          subTitle
          searchTitle
          placeholder
          searchBtn
          collapseButton
          collapseQuestion
          choseMore
          collapseP1
          collapseP2
          studyStatus
          searchTooltip {
            childMarkdownRemark {
              html
            }
          }
        }
        additionalFiltersJson {
          title
          condition
          modalCondition {
            title
            healthyVolunteers
            selectedCondition
            clearBtn
            okBtn
          }
          country
          postalCode
          studyType
          patientLevelData
          ageRange
          gender
          phases
          disclousureType
          collaborators
          resetBtn
          searchBtn
        }
      }
    `}
    render={data => <HomeSearch data={data} {...props} />}
  />
);

export default HomeSearchQuery;

HomeSearch.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};
