import styled from 'styled-components';
import { pinkFont } from '../../constants/theme';
export const Search = styled.div`
  margin-top: 84px;
  .keyword-search {
    svg {
      color: ${pinkFont};
      margin-left: 5px;
    }
  }
  .containerS {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    paddign: 0;
    a,
    button {
      :hover {
        text-decoration: underline;
      }
    }
  }

  @media (min-width: 768px) {
    .containerS {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
      margin-left: 8.333333%;
      padding: 0 15px;
    }
  }
  .additional-title {
    margin-top: 40px;
    margin-bottom: 10px;
    line-height: 24px;
    font-size: 30px;
  }
  margin-bottom: 42px;
  .resetBtn {
    padding: 6px 24px;
    margin-right: 8px;
    line-height: 24px;
  }
  .bottom-search {
    text-align: right;
  }
  .searchBtn {
    padding: 6px 48px;
    margin-right: 4px;
    line-height: 24px;
  }
  .separator {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    margin: 0 15px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    p {
      &.title {
        font-weight: bold;
        margin-bottom: 8px;
        line-height: 19px;
      }
    }
  }
  select {
    border: 1px solid #ddd;
    padding: 4px 8px;
    font-size: 14px;
    width: 100%;
    color: #495057;
    &:focus {
      outline: none;
    }
  }
  .select-dropdown {
    width: 100%;
    text-align: left;
    background-color: transparent;
    border: 1px solid #ddd;
    padding: 0 36px 0 10px;

    p {
      font-size: 14px;
      line-height: 30px;
      font-weight: 400;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #808080;
    }
    .arrowRight {
      display: block;
      position: absolute;
      right: 4px;
      top: 0;
      width: 32px;
      height: 32px;
      background-color: #f8f8f8;
      color: #bbb;
      text-align: center;
      background: url(/icons/select-arrow.png) no-repeat center center;
    }
  }
  .zip {
    font-size: 14px;
    line-height: 21px;
    width: 100%;
    padding: 4px 8px;
    border: 1px solid #ced4da;
  }
  .dropdown-menu.show {
    margin-top: 0;
    width: 100%;
    .selected {
      background-color: ${pinkFont};
      color: white !important;
    }
    .dropdown-item {
      font-size: 12px;
      line-height: 20px;
      max-width: 100%;
      padding: 4px 10px;
      white-space: normal;
      color: #808080;
      &:hover {
        background-color: ${pinkFont};
        color: white;
      }
    }
  }
  .bottomLine {
    border-bottom: 1px solid #d5d1ce;
  }
  .collaborator {
    .dropdown-menu {
      -ms-overflow-y: auto;
    }
  }
  .noPad {
    padding: 0;
  }
  .icon-plus,
  .icon-minus {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    display: inline-block;
    vertical-align: middle;
  }
  .icon-plus {
    background: url(/icons/up-plus.png) no-repeat center center;
  }
  .icon-minus {
    background: url(/icons/up-minus.png) no-repeat center center;
  }
  .title-section {
    h1 {
      margin-bottom: 10px;
    }
  }
  .trial-name {
    font-size: 16px;
    margin-right: 16px;
    line-height: 24px;
  }
  .status-boxes {
    display: flex;
    .radio-btn {
      label {
        font-size: 16px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    .genderClass {
      padding-right: 16px;
      .check {
        margin-top: 3px;
      }
      .padding-correction {
        margin-top: 0px;
        margin-bottom: 0px;
        line-height: 24px;
      }
    }
  }
  .rightSearch {
    text-align: right;
  }
  .help-section {
    font-size: 14px;
    line-height: 21px;
    margin-top: 16px;
    p {
      margin-bottom: 8px;
    }
  }
  .toggleBtn {
    display: inline;
    background: transparent;
    border: none;
    color: #00a0af;
    font-weight: bold;
    svg {
      color: ${pinkFont};
      font-size: 20px;
      margin-left: 8px;
    }
  }

  .search-info {
    p {
      font-size: 14px;
      color: #212529;
    }
  }
  .bgGrey {
    background-color: #f5f5f5;
    padding: 5px 10px;
    margin: 0 0 10px 0;
  }
  .search-btn {
    display: flex;
    padding: 6px 46px;
    line-height: 24px;
  }
  .redBtn {
    font-size: 16px;
    background-color: ${pinkFont};
    color: white;
    border: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover {
      background-color: #c11831;
    }
    &:focus {
      background-color: #c11831;
    }
  }
  .suggestions {
    z-index: 7;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 30px;
    width: calc(100% - 204px);
    top: 54px;
    background-color: white;
    list-style: none;
    font-size: 16px;
    padding: 0 20px 20px 20px;
    overflow: hidden;
    border: 1px solid;
    li {
      max-width: 1070px;
      width: 100%;
      margin-bottom: 6px;
      cursor: pointer;
    }
  }
  .searchBar {
    display: flex;
    margin-bottom: 10px;
    input {
      position: relative;
      flex: 1 1 auto;
      width: 1%;
      padding: 10px 16px;
      font-size: 14px;
      margin-right: 4px;
      border: 1px solid #ced4da;
      background-image: url(/icons/search-icon.png);
      background-position: 99% center;
      background-repeat: no-repeat;
    }
  }

  .phases-boxes2 {
    display: flex;
    flex-wrap: wrap;
    .mainContainer {
      flex: 0 0 33%;
      max-width: 33%;
    }
    .mainContainer:nth-child(3n + 3),
    .mainContainer:nth-child(3n + 2) {
      padding: 0 15px;
    }
  }

  //MOBILE AND TABLET
  @media (max-width: 992px) {
    .phases-boxes2 {
      display: flex;
      flex-wrap: wrap;
      .mainContainer {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .mainContainer:nth-child(3n + 3),
      .mainContainer:nth-child(3n + 2) {
        padding: 0;
      }
    }
    .rightSearch {
      text-align: left;
    }
    .trial-name {
      width: 100%;
    }
    .status-boxes {
      flex-direction: column;
    }
    .left-search {
      margin-bottom: 5px;
    }
    .additional-title {
      line-height: 36px;
    }
    .country {
      margin-top: 8px;
    }
    .sectionTwo {
      .dropdown {
        margin-bottom: 15px;
      }
      p {
        &.study {
          line-height: 24px;
        }
      }
    }
    .phases {
      margin-bottom: 20px;
    }
    .firstRow {
      padding-bottom: 0;
      .dropdown {
        margin-bottom: 10px;
      }
      p {
        &.title {
          line-height: 24px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .additional-title,
    .title-section h1 {
      font-size: 26px;
    }
    .bottom-search {
      text-align: center;
    }
  }

  //MOBILE
  @media (max-width: 384px) {
    margin-top: 56px;
  }

  @media all and (hover: none) {
    .redBtn {
      &:hover {
        background-color: ${pinkFont};
      }
    }
  }

  @media (max-width: 364px) {
    .resetBtn {
      margin-bottom: 8px;
    }
  }
`;
